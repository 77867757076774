export const stringify = (queryParams: Record<string, any>): string =>
  Object.keys(queryParams)
    .map((key) => {
      const value = queryParams[key]
      return Array.isArray(value)
        ? value
            .map(
              (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            )
            .join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

export const parse = (queryString: string): Record<string, any> =>
  queryString
    .replace(/^\?/, '')
    .split('&')
    .reduce(
      (acc, queryParam) => {
        const [key, value] = queryParam.split('=')
        const decodedKey = decodeURIComponent(key)
        const decodedValue = decodeURIComponent(value || '')

        if (!acc[decodedKey]) {
          acc[decodedKey] = decodedValue
        } else if (Array.isArray(acc[decodedKey])) {
          acc[decodedKey].push(decodedValue)
        } else {
          acc[decodedKey] = [acc[decodedKey], decodedValue]
        }

        return acc
      },
      {} as Record<string, any>
    )
