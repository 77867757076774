import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

const useTrackingCookies = () => {
  const router = useRouter()

  useEffect(() => {
    const appDomain =
      typeof window === 'undefined' ? '' : window.location.hostname
    const visitDate = new Date().toISOString()

    const setCookie = (name: string, value: string) => {
      if (value) {
        Cookies.set(name, value, {
          domain: appDomain,
          path: '/',
          secure: true,
          sameSite: 'None' // Allow subdomains to access the cookie
        })
      }
    }

    const tad = Cookies.get('TrackingAppDomain')
    if (!tad || tad !== appDomain) {
      setCookie('TrackingAppDomain', appDomain)
    }

    if (!Cookies.get('TrackingGeneratedAtUtc')) {
      setCookie('TrackingGeneratedAtUtc', visitDate)
    }

    // Convert all query parameters to lower case
    const query = Object.fromEntries(
      Object.entries(router.query).map(([key, value]) => [
        key.toLowerCase(),
        value
      ])
    )

    const {
      affiliate_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      referrer
    } = query

    const shouldSetCookies =
      affiliate_id ||
      utm_source ||
      utm_medium ||
      utm_campaign ||
      utm_term ||
      utm_content ||
      referrer

    if (!shouldSetCookies) return

    // Definindo cookies
    setCookie('TrackingAffiliateId', affiliate_id as string)
    setCookie('TrackingUtmSource', utm_source as string)
    setCookie('TrackingUtmMedium', utm_medium as string)
    setCookie('TrackingUtmCampaign', utm_campaign as string)
    setCookie('TrackingUtmTerm', utm_term as string)
    setCookie('TrackingUtmContent', utm_content as string)
    setCookie('TrackingReferrer', referrer as string)
  }, [router.query])
}

export default useTrackingCookies
