'use client'

import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

const useMixpanel = (token: string, environment: string) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !token) return
    if (!mixpanel.has_init) {
      mixpanel.init(token, {
        debug: environment !== 'production',
        track_pageview: true,
        persistence: 'cookie'
      })
      mixpanel.has_init = true
    }
  }, [token, environment])
}

export default useMixpanel
