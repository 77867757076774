import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import { AuthMetadata } from '@/types/global'
import { TOKEN_KEY } from '@/constants/LocalStorageTokens'

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SIGNATER_API}/v1`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

let isRefreshing: Promise<string> | null = null

api.interceptors.request.use(
  (config) => {
    const isSandbox = localStorage.getItem('isSandbox')
    const authMetadataJSON = localStorage.getItem(TOKEN_KEY)
    const authMetadata = JSON.parse(authMetadataJSON || '{}')
    // const userContext = JSON.parse(localStorage.getItem('userContext') || '{}')

    config.headers['X-Signater-SandboxMode'] = isSandbox

    // if (userContext?.userAccountInformation?.id) {
    //   config.headers['X-Signater-UserAccountId'] =
    //     userContext?.userAccountInformation?.id
    // }

    const hasAuthMetadata =
      authMetadataJSON &&
      authMetadata?.accessToken &&
      authMetadata?.refreshToken

    if (authMetadataJSON && hasAuthMetadata) {
      const authMetadata: AuthMetadata = JSON.parse(authMetadataJSON)
      config.headers['Authorization'] = `Bearer ${authMetadata.accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const transformHeaders = (
  headers: Record<string, string>
): Record<string, string> => {
  if (!headers) return headers
  return Object.keys(headers).reduce(
    (acc, key) => {
      acc[key] = headers[key]
      return acc
    },
    {} as Record<string, string>
  )
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error
    const userContext = JSON.parse(localStorage.getItem('userContext') || '{}')
    const userInfo = userContext?.userAccountInformation

    if (response && response.status >= 400 && response.status < 500) {
      return Promise.reject(error)
    }

    const responseHeaders = transformHeaders(error.response.headers)

    Sentry.withScope((scope) => {
      scope.setContext('axios', {
        url: error.config.url,
        method: error.config.method,
        data: error.config.data,
        params: error.config.params,
        response: response
          ? {
              status: response.status,
              data: response.data,
              headers: transformHeaders(response.headers)
            }
          : null,
        ...responseHeaders
      })

      if (userInfo) {
        scope.setUser({
          id: userInfo.id,
          email: userInfo.email,
          username: userInfo.username
        })
      }

      scope.setFingerprint([
        error.config.method,
        error.config.url,
        response ? response.status.toString() : 'no_response'
      ])
      scope.setTransactionName(
        `API ${error.config.method.toUpperCase()} ${error.config.url}`
      )
      Sentry.captureException(error)
    })

    const originalRequest = error.config

    if (originalRequest.url === '/auth/refresh') {
      const themeKey = 'theme'
      const rememberLoginEmailKey = 'rememberLoginEmail'

      const theme = localStorage.getItem(themeKey)
      const rememberLoginEmail = localStorage.getItem(rememberLoginEmailKey)

      localStorage.clear()

      localStorage.setItem(themeKey, theme || 'light')
      localStorage.setItem(rememberLoginEmailKey, rememberLoginEmail || '')

      window.location.href = '/login'
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!isRefreshing) {
        const authMetadataJSON = localStorage.getItem(TOKEN_KEY)
        if (!authMetadataJSON) {
          return Promise.reject(error)
        }

        const authMetadata: AuthMetadata = JSON.parse(authMetadataJSON)
        isRefreshing = api
          .post('/auth/refresh', {
            accessToken: authMetadata.accessToken,
            refreshToken: authMetadata.refreshToken
          })
          .then((response) => {
            axios.defaults.headers.common['Authorization'] =
              `Bearer ${response.data.accessToken}`
            authMetadata.accessToken = response.data.accessToken // Update token
            localStorage.setItem(
              TOKEN_KEY,
              JSON.stringify({
                ...authMetadata,
                expiresInDate: new Date(
                  new Date().getTime() + authMetadata.expiresIn * 1000
                )
              })
            )
            isRefreshing = null
            return response.data.accessToken
          })
          .catch((refreshError) => {
            isRefreshing = null
            window.location.href = '/logout'
            console.error('Error refresh token:', refreshError)
            return Promise.reject(refreshError)
          })
      }

      return isRefreshing.then((newToken) => {
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`
        return api(originalRequest)
      })
    }

    return Promise.reject(error)
  }
)

export default api
