import { useState, useEffect } from 'react'

export type IBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

interface ViewportInfo {
  width: number
  height: number
  breakpoint: IBreakpoint
}

const useViewport = (): ViewportInfo => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })
  const [breakpoint, setBreakpoint] = useState<IBreakpoint>('2xl')

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })

      if (window.innerWidth < 640) {
        setBreakpoint('xs')
      } else if (window.innerWidth >= 640 && window.innerWidth < 768) {
        setBreakpoint('sm')
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setBreakpoint('md')
      } else if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
        setBreakpoint('lg')
      } else if (window.innerWidth >= 1280 && window.innerWidth < 1536) {
        setBreakpoint('xl')
      } else if (window.innerWidth >= 1536 && window.innerWidth < 1920) {
        setBreakpoint('2xl')
      } else {
        setBreakpoint('3xl')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { width: windowSize.width, height: windowSize.height, breakpoint }
}

export default useViewport
