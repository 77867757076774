import { useEffect } from 'react'
import Gleap from 'gleap'

const useGleap = (gleapKey: string) => {
  useEffect(() => {
    if (!gleapKey) return
    Gleap.initialize(gleapKey)
  }, [gleapKey])
}

export default useGleap
