import { type ClassValue, clsx } from 'clsx'
import sha256 from 'crypto-js/sha256'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const hashToSha256WithoutSalt = (value: string): string =>
  sha256(value.trim().toLowerCase()).toString()
