import { createContext, useContext, useCallback } from 'react'
import { useRouter } from 'next/router'
import {
  QueryParamsContextType,
  QueryParamsProviderProps
} from './QueryParams.types'

const QueryParamsContext = createContext<QueryParamsContextType | undefined>(
  undefined
)

export const QueryParams = ({ children }: QueryParamsProviderProps) => {
  const { query, replace, isReady: routerIsReady } = useRouter()

  const setQueryParam = useCallback(
    <T,>(key: string, newValue: T | null) => {
      if (!routerIsReady) return

      const newQuery = { ...query }
      if (newValue === null) {
        delete newQuery[key]
      } else {
        newQuery[key] =
          typeof newValue === 'string' || Array.isArray(newValue)
            ? newValue
            : String(newValue)
      }

      replace({ query: newQuery }, undefined, { shallow: true })
    },
    [query, replace, routerIsReady]
  )

  const getQueryParam = useCallback(
    <T,>(key: string, defaultValue: T | null): T | null => {
      const value = query[key]
      return value !== undefined ? (value as T) : defaultValue
    },
    [query]
  )

  return (
    <QueryParamsContext.Provider
      value={{ getQueryParam, setQueryParam, isReady: routerIsReady }}
    >
      {children}
    </QueryParamsContext.Provider>
  )
}

export const useQueryParams = () => {
  const context = useContext(QueryParamsContext)
  if (!context) {
    throw new Error('useQueryParams must be used within a QueryParamsProvider')
  }
  return context
}
