import React, { Component, ReactNode, ErrorInfo } from 'react'
import * as Sentry from '@sentry/nextjs'

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: null
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorInfoFormatted = { componentStack: errorInfo.componentStack }
    Sentry.captureException(error, { extra: errorInfoFormatted })

    this.setState({ errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return <h1>Algo deu errado.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
