import { useEffect } from 'react'
import { useRouter } from 'next/router'

const useRedirect = () => {
  const router = useRouter()

  useEffect(() => {
    if (window.location.hostname === 'signater-app.pages.dev') {
      router.push('https://app.signater.com')
    }
  }, [router])
}

export default useRedirect
