import * as Sentry from '@sentry/nextjs'
import { QueryClient, MutationCache, QueryCache } from '@tanstack/react-query'

const createQueryClient = () => {
  return new QueryClient({
    mutationCache: new MutationCache({
      onError: (err, _variables, _context, mutation) => {
        const userContext = JSON.parse(
          localStorage.getItem('userContext') || '{}'
        )
        const userInfo = userContext?.userAccountInformation

        Sentry.withScope((scope) => {
          if (err instanceof Error) {
            scope.setContext('mutation', {
              mutationId: mutation.mutationId,
              variables: mutation.state.variables,
              queryKey: mutation.options.mutationKey,
              queryFn: mutation.options.mutationFn
            })

            if (userInfo) {
              scope.setUser({
                id: userInfo.id,
                email: userInfo.email,
                username: userInfo.username
              })
            }

            if (mutation.options.mutationKey) {
              scope.setFingerprint(
                Array.from(mutation.options.mutationKey) as string[]
              )
            } else {
              scope.setFingerprint(['unknown-mutation-key'])
            }

            Sentry.configureScope((scope) => {
              scope.setTransactionName(
                `Mutation ${mutation.options.mutationKey}`
              )
            })
            Sentry.captureException(err)
          } else {
            Sentry.captureMessage('Unknown error in mutation')
          }
        })
      }
    }),
    queryCache: new QueryCache({
      onError: (err, query) => {
        const userContext = JSON.parse(
          localStorage.getItem('userContext') || '{}'
        )
        const userInfo = userContext?.userAccountInformation

        Sentry.withScope((scope) => {
          if (err instanceof Error) {
            scope.setContext('query', {
              queryHash: query.queryHash,
              queryKey: query.queryKey,
              queryFn: query.options.queryFn
            })

            if (userInfo) {
              scope.setUser({
                id: userInfo.id,
                email: userInfo.email,
                username: userInfo.username
              })
            }

            scope.setFingerprint([query.queryHash.replace(/[0-9]/g, '0')])
            Sentry.configureScope((scope) => {
              scope.setTransactionName(`Query ${query.queryKey}`)
            })
            Sentry.captureException(err)
          } else {
            Sentry.captureMessage('Unknown error in query')
          }
        })
      }
    }),
    defaultOptions: {
      queries: {
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        onError: (err: unknown) => {
          const userContext = JSON.parse(
            localStorage.getItem('userContext') || '{}'
          )
          const userInfo = userContext?.userAccountInformation

          Sentry.withScope((scope) => {
            if (err instanceof Error) {
              scope.setContext('query', {
                error: err.message,
                stack: err.stack
              })

              if (userInfo) {
                scope.setUser({
                  id: userInfo.id,
                  email: userInfo.email,
                  username: userInfo.username
                })
              }

              Sentry.configureScope((scope) => {
                scope.setTransactionName('Query Error')
              })
              Sentry.captureException(err)
            } else {
              Sentry.captureMessage('Unknown error in query')
            }
          })
          console.error('Query Error:', err)
        }
      },
      mutations: {
        cacheTime: 0,
        onError: (err: unknown) => {
          const userContext = JSON.parse(
            localStorage.getItem('userContext') || '{}'
          )
          const userInfo = userContext?.userAccountInformation

          Sentry.withScope((scope) => {
            if (err instanceof Error) {
              scope.setContext('mutation', {
                error: err.message,
                stack: err.stack
              })

              if (userInfo) {
                scope.setUser({
                  id: userInfo.id,
                  email: userInfo.email,
                  username: userInfo.username
                })
              }

              Sentry.configureScope((scope) => {
                scope.setTransactionName('Mutation Error')
              })
              Sentry.captureException(err)
            } else {
              Sentry.captureMessage('Unknown error in mutation')
            }
          })
          console.error('Mutation Error:', err)
        }
      }
    }
  })
}

export default createQueryClient
