const content = {
  labelClose: 'Fechar',
  errorRefreshProfile: 'Erro ao buscar informações do perfil',
  errorInvalidFields: 'E-mail ou senha inválidos',
  errorEmailIsNotAvailable: 'E-mail já cadastrado',
  errorInRegister: 'Erro ao criar conta',
  errorCodeIsNotValid: 'Código inválido',
  successInRecoveryPassword: 'Senha recuperada',
  errorInRecoveryPassword: 'Erro ao recuperar senha',
  errorInSendCode: 'Erro ao enviar código',
  errorPasswordLength:
    'A senha deve ter no mínimo 8 caracteres e no máximo 255 caracteres',
  errorLoginAction:
    'Erro ao fazer login. Verifique suas credenciais e tente novamente.',
  errorRegisterAction: 'Erro ao criar conta',
  errorRegisterGoogle:
    'Você não possui vínculo com a conta Google. Por favor, faça login com seu usuário e senha.',
  errorDontHaveAccount: 'Você não pertence a nenhuma conta'
}

export default content
