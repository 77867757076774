'use client'

import React, { ReactElement, ReactNode } from 'react'
import { TrackingHeadScript } from '@phntms/next-gtm'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import '../styles/global.css'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'next-themes'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import useFacebookPixel from '@/hooks/useFacebookPixel'
import useGleap from '@/hooks/useGleap'
import useMixpanel from '@/hooks/useMixpanel'
import useRedirect from '@/hooks/useRedirect'
import useResizeHandler from '@/hooks/useResizeHandler'
import useTrackingCookies from '@/hooks/useTrackingCookies'
import { useViewport } from '@/hooks/useViewport'
import { Providers } from '@/providers/Providers'
import SEO from '../../next-seo.config'
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css'
import createQueryClient from '@/lib/reactQueryClient'
import ErrorBoundary from '@/ui/atoms/ErrorBoundary'

export type NextPageWithLayout<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || ''
const GLEAP_KEY = process.env.NEXT_PUBLIC_GLEAP_KEY || ''
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || ''
const MIXPANEL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_MIXPANEL_ENVIRONMENT || 'production'
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''
function App({ Component, pageProps }: AppPropsWithLayout) {
  const queryClient = createQueryClient()

  useGleap(GLEAP_KEY)
  useMixpanel(MIXPANEL_TOKEN, MIXPANEL_ENVIRONMENT)
  useFacebookPixel()
  useResizeHandler()
  useRedirect()
  useTrackingCookies()

  const { breakpoint } = useViewport()

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <React.StrictMode>
      <Head>
        <title>Signater App</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="shortcut icon"
          href="/assets/logos/signater/yellow-black-square.png"
        />
        <link
          rel="apple-touch-icon"
          href="/assets/logos/signater/yellow-black-square.png"
        />
        <DefaultSeo {...SEO} />
      </Head>
      <GoogleReCaptchaProvider useEnterprise reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {process.env.NEXT_PUBLIC_APP_ENV === 'local' &&
              breakpoint !== 'xs' &&
              breakpoint !== 'sm' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            <ThemeProvider
              attribute="class"
              enableSystem={false}
              themes={['dark', 'light']}
            >
              <Providers>
                {getLayout(<Component {...pageProps} />)}
                <TrackingHeadScript id={GTM_CONTAINER_ID} isGTM={true} />
                <GoogleAnalytics trackPageViews />
              </Providers>
            </ThemeProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  )
}

export default App
