import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useFacebookPixel = () => {
  const router = useRouter()
  const [pixel, setPixel] = useState(null)

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((p: any) => {
        setPixel(p)
        p.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID)
        p.pageView()

        router.events.on('routeChangeComplete', () => {
          p.pageView()
        })
      })
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.events.off('routeChangeComplete', () => {})
    }
  }, [router.events])

  return pixel
}

export default useFacebookPixel
